.portfolio-page {
  .flat-button {
    color: #ffd700;
    background: 0 0;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;
    border-radius: 4px;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background-color: #ffd700;
      color: #333;
    }
  }
}

.card-container {
  position: absolute;
  right: 15px;
  width: 53%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 1%;

  h1 {
    color: #ffd700;
    font-size: 40px;
    margin: 0;
    font-family: 'Colvetica';
    font-weight: 400;
  }
  .large-card {
    background: rgb(199, 203, 133);
    width: calc(100% - 12px);
    opacity: 0;
    animation: fadeInUp 1s 1.5s;
    animation-fill-mode: forwards;
    .card-action {
      height: 100%;
    }
  }
  .card {
    background: rgb(199, 203, 133);
    max-width: calc(50% - 10px);
    opacity: 0;
    animation: fadeInUp 1.5s 1.5s;
    animation-fill-mode: forwards;
    .card-action {
      height: 100%;
    }
  }
  .experience-list {
    .experience {
      align-items: center;
      display: flex;
      column-gap: 5px;
      opacity: 0;
      animation: fadeInUp 1.5s 1.5s;
      animation-fill-mode: forwards;
      img {
        align-items: center;
        height: 18px;
        border-radius: 5px;
      }
      .experience-title {
        margin: 0;
        font-size: 18px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
      }
      sub {
        color: rgb(160, 160, 160);
        font-family: sans-serif;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .card-container {
      position: initial;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      .large-card {
        max-width: calc(100% - 16px);
      }
      .card {
        max-width: calc(50% - 10px);
        height: auto;
      }
    }
  }
}
