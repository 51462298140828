.contact-form {
  width: 100%;
  margin-top: 20px;

  .input-form {
    border-radius: 5px;
    background-color: transparent;
    border: #ffd700 solid 1px;
    color: white;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      opacity: 0;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 40%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: transparent;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    outline: none !important;
  }

  textarea {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 16px;
    padding: 20px;
    color: #fff;
    min-height: 150px;
    background: transparent;
    box-sizing: border-box;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  .flat-button {
    color: #ffd700;
    background: 0 0;
    font: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: right;
    border-radius: 4px;
    transition: 0.3s all;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background-color: #ffd700;
      color: white;
    }
  }
}

.info-map {
  position: absolute;
  background-color: black;
  top: 10px;
  right: 1%;
  z-index: 999999;
  width: 250px;
  padding: 20px;
  color: white;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;
    display: block;
    padding-top: 20px;
    color: #ffd700;
  }
}

.map-wrap {
  background: rgb(199, 203, 133);
  float: right;
  width: 53%;
  height: 100%;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

p.second-paragraph {
  opacity: 0;
  animation: fadeInUp 2s 4s !important;
  animation-fill-mode: forwards !important;
}

@media screen and (max-width: 1200px) {
  p.second-paragraph {
    display: none;
  }
  .map-wrap {
    float: none;
    width: 100%;
    height: 400px;
    margin: 0;
  }

  .info-map {
    display: none;
  }
}
